import React from 'react';
import { Route, Routes } from "react-router-dom";
import AboutOne from "./components/AboutPages/AboutOne/index";
import Blog from "./components/BlogPages/Blog";
import BlogDetails from "./components/BlogPages/BlogDetails";
import Contact from "./components/Contact/index";
import CounterPage from "./components/CounterPage";
import FourZeroFour from "./components/FourZeroFour/index";
import HomeOne from "./components/HomeOne";
import ProtfolioDetails from "./components/PortfolioPages/PortfolioDetails";
import PortfolioOne from "./components/PortfolioPages/PortfolioOne";
import PricingOne from "./components/PricingPages/PricingOne";
import ServiceDetails from "./components/ServicePages/ServiceDetails/index";
import ServiceOne from "./components/ServicePages/ServiceOne/index";
import Shop from "./components/ShopPages/Shop";
import ShopDetails from "./components/ShopPages/ShopDetails";
import TeamOne from "./components/TeamPages/TeamOne";
import TeamSingle from "./components/TeamPages/TeamSingle";
import TestimonialOne from "./components/TestimonialPages/TestmonialOne";

export default function Routers() {
  return (
    <Routes>
      <Route exact path="/" element={<HomeOne />} />
      <Route exact path="/about-one" element={<AboutOne />} />
      <Route exact path="/service-one" element={<ServiceOne />} />
      <Route exact path="/service-details" element={<ServiceDetails />} />
      <Route exact path="/portfolio-one" element={<PortfolioOne />} />
      <Route exact path="/portfolio-details" element={<ProtfolioDetails />} />
      <Route exact path="/team-one" element={<TeamOne />} />
      <Route exact path="/team-single" element={<TeamSingle />} />
      <Route exact path="/testimonial-one" element={<TestimonialOne />} />
      <Route exact path="/pricing" element={<PricingOne />} />
      <Route exact path="/shop" element={<Shop />} />
      <Route exact path="/shop-details" element={<ShopDetails />} />
      <Route exact path="/blog" element={<Blog />} />
      <Route exact path="/blog/blog-details" element={<BlogDetails />} />
      <Route exact path="/404" element={<FourZeroFour />} />
      <Route exact path="/contact" element={<Contact />} />
      <Route exact path="/counters" element={<CounterPage />} />
    </Routes>
  );
}
