import React from "react";

function Navigation({ className }) {
  return (
    <>
      <div className={`appie-header-main-menu ${className || ""}`}>
        <ul>
          <li>
            <a href="/">
              Home
            </a>
          </li>
          <li>
            <a href="/about-one">
              About Us
            </a>
          </li>
          <li>
            <a href="#">
              Services <i className="fa fa-angle-down" />
            </a>
            <ul className="sub-menu">
              <li>
                <a href="/service-one">IT Consulting</a>
              </li>
              <li>
                <a href="/service-two">Web Development</a>
              </li>
              <li>
                <a href="/service-three">Cloud Services</a>
              </li>
              <li>
                <a href="/service-four">IT Management</a>
              </li>
              <li>
                <a href="/service-five">Software Development</a>
              </li>
              <li>
                <a href="/service-six">Data Migration</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="/contact">Contact</a>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Navigation;
